<app-header></app-header>
<app-carousel></app-carousel>

<!-- <div class="search-mobile div-icon-search">
  <a fxShow="true" fxHide.gt-sm="true" href="javascript:void(0);" class="icon icon-search" (click)="goToHome('estate')">
    <mat-icon class="search-button-icon">search</mat-icon>
  </a>
</div> -->

<!-- <div class="search-mobile div-icon-search">
  <a fxShow="true" fxHide.gt-sm="true" href="javascript:void(0);" class="icon icon-search">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon>dialpad</mat-icon>
        <span>Redial</span>
      </button>
      <button mat-menu-item disabled>
        <mat-icon>voicemail</mat-icon>
        <span>Check voice mail</span>
      </button>
      <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon>
        <span>Disable alerts</span>
      </button>
    </mat-menu>
  </a>
</div> -->

<!-- <div style="z-index: -10;" class="mobile-video">
  <video fxFill autoplay onloadedmetadata="this.muted=true" oncanplay="this.play()" loop="loop">
    <source src="../assets/header-bg-final.mp4" type="video/mp4">
  </video>
  <div class="slogan mobile-slogan">
    <p>Настройтесь на путешествия вместе с нами</p>
  </div>
  <div class="div-img-header">
    <img class="img-header" height="300px" width="100%" style="position: relative; object-fit: cover"
      src="../../assets/rl-bottom-test3.jpg" alt="">
  </div>
  <div class="image-container">
    <img class="img-header" height="300px" width="100%" style="position: relative; object-fit: cover" src="../../assets/rl-bottom-test3.jpg" alt="" style="width:100%;">
    <div class="slogan mobile-slogan">{{"Home.slogan" | translate}}</div>
  </div>

</div>
<span class="mobile-menu mat-card-menu">
  <div fxLayoutAlign="space-around center" style="position: sticky; top: 80px;z-index: 2;">
      <mat-card class="mat-icons" fxLayout="row" style="border-radius: 0 0 50px 50px;height: 80px; color: rgba(0, 0, 0, 0.507); font-family: Georgia, 'Times New Roman', Times, serif;"
        [style.height.px]="[navigationHeight]">

          <a routerLink="/{{filtered_website_language}}/home/estate" fxLayoutAlign="center" (click)="goToHome('estate')"
              class="menu_a_cursor">
              <div class="div-icons-header" fxLayout="column">
                <p class="p-icon" fxLayoutAlign="center">{{'Home.hotel' | translate }}</p>
                <i *ngIf="!hideTextInNavigation" class="material-icons md-48 md-48-bottom-header" style="margin-bottom: 10px; margin-top:-10px">hotel</i>
              </div>
          </a>

          <a routerLink="/{{filtered_website_language}}/home/adventure" fxLayoutAlign="center" (click)="goToHome('adventure')"
              class="menu_a_cursor">
              <div class="div-icons-header" fxLayout="column">
                <p class="p-icon" fxLayoutAlign="center">{{'Home.adventure' | translate }}</p>
                <i *ngIf="!hideTextInNavigation" class="material-icons md-48 md-48-bottom-header" style="margin-bottom: 10px; margin-top:-10px">account_balance</i>
              </div>
          </a>

          <a routerLink="/{{filtered_website_language}}/home/flight" fxLayoutAlign="center" (click)="goToHome('flight')"
              class="menu_a_cursor">
              <div class="div-icons-header" fxLayout="column">
                <p class="p-icon" fxLayoutAlign="center">{{'Home.flights' | translate }}
                </p>
                <i *ngIf="!hideTextInNavigation" class="material-icons md-48 md-48-bottom-header" style="margin-bottom: 10px; margin-top:-10px">airplanemode_active</i>
              </div>
          </a>

          <a routerLink="/{{filtered_website_language}}/home/rent-a-car" fxLayoutAlign="center" (click)="goToHome('rent-a-car')"
              class="menu_a_cursor">
              <div class="div-icons-header" fxLayout="column">
                <p class="p-icon" fxLayoutAlign="center">{{'Home.rentcar' | translate}}</p>
                <i *ngIf="!hideTextInNavigation" class="material-icons md-48 md-48-bottom-header" style="margin-bottom: 10px; margin-top:-10px">car_rental</i>
              </div>
          </a>

          <a href="https://nobiarts.com" target="_blank"
          fxLayoutAlign="center"
            class="menu_a_cursor">
            <div class="div-icons-header" fxLayout="column">
              <p class="p-icon" fxLayoutAlign="center">{{'Home.marketNobiArts' | translate}}</p>
              <i *ngIf="!hideTextInNavigation" class="material-icons md-48 md-48-bottom-header" style="margin-bottom: 10px; margin-top:-10px">shop</i>
            </div>
          </a>

          <a routerLink="/{{filtered_website_language}}/home/personal-transfer" fxLayoutAlign="center" (click)="goToHome('personal-transfer')"
                class="menu_a_cursor">
                  <div class="div-icons-header" fxLayout="column">
                    <p class="p-icon" fxLayoutAlign="center">{{'Home.personalTransfer' | translate }}</p>
                    <i *ngIf="!hideTextInNavigation" class="material-icons md-48 md-48-bottom-header" style="margin-bottom: 10px; margin-top:-10px">transfer_within_a_station</i>
                  </div>
              </a>

              <a routerLink="/{{filtered_website_language}}/home/avia-bus-russia" fxLayoutAlign="center" (click)="goToHome('avia-bus-russia')"
              class="menu_a_cursor">
                <div class="div-icons-header" fxLayout="column">
                  <p class="p-icon" fxLayoutAlign="center">{{'Home.bus' | translate }}</p>
                  <i *ngIf="!hideTextInNavigation" class="material-icons md-48 md-48-bottom-header" style="margin-bottom: 10px; margin-top:-10px">directions_bus</i>
                </div>
            </a>

              

              <a routerLink="/{{filtered_website_language}}/home/insurance" fxLayoutAlign="center" (click)="goToHome('insurance')"
              class="menu_a_cursor">
              <div class="div-icons-header" fxLayout="column">
                <p class="p-icon" fxLayoutAlign="center">{{'Home.insurance' | translate }}</p>
                <i *ngIf="!hideTextInNavigation"  class="material-icons md-48 md-48-bottom-header" style="margin-bottom: 10px; margin-top:-10px">security</i>
              </div>
            </a> -->

<!-- <div class="div-icons-header" fxLayout="column" style="margin: 0px 30px 0px 20px">
              <button mat-icon-button [matMenuTriggerFor]="menu"
                  style="font-size: 36px;margin: -13% 0px 7px 0px;height: 40px;width: 40px;">
                  <mat-icon>more_vert</mat-icon>
              </button>
          </div>

          <mat-menu #menu="matMenu" class="moreMenu">
              <a routerLink="/{{filtered_website_language}}/home/personal-transfer" fxLayoutAlign="center" (click)="goToHome('personal-transfer')"
                class="menu_a_cursor">
                  <div class="div-icons-header" fxLayout="column">
                    <p class="p-icon" fxLayoutAlign="center">{{'Home.personalTransfer' | translate }}</p>
                    <i *ngIf="!hideTextInNavigation" class="material-icons md-48 md-48-bottom-header" style="margin-bottom: 10px; margin-top:-10px">transfer_within_a_station</i>
                  </div>
              </a>

              <a routerLink="/{{filtered_website_language}}/home/insurance" fxLayoutAlign="center" (click)="goToHome('insurance')"
              class="menu_a_cursor">
              <div class="div-icons-header" fxLayout="column">
                <p class="p-icon" fxLayoutAlign="center">{{'Home.insurance' | translate }}</p>
                <i *ngIf="!hideTextInNavigation"  class="material-icons md-48 md-48-bottom-header" style="margin-bottom: 10px; margin-top:-10px">security</i>
              </div>
            </a> -->

<!-- <a routerLink="/{{filtered_website_language}}/home/restaurant" fxLayoutAlign="center" (click)="goToHome('restaurant')"
                  class="menu_a_cursor">
                  <div class="div-icons-header" fxLayout="column">
                      <i class="material-icons md-48 md-48-bottom-header" style="margin-bottom: 10px; margin-top:-10px">fastfood</i>
                      <p *ngIf="!hideTextInNavigation" class="p-icon" fxLayoutAlign="center">{{'Home.restaurants' | translate
                          }}</p>
                  </div>
              </a> -->
<!-- </mat-menu> -->

<!-- </mat-card>
  </div>
</span> -->

<!-- <div *ngIf="showLoader" class="spinner-position faq_spinner">
    <mat-spinner [diameter]="100"></mat-spinner>
  </div> -->
<div *ngIf="reveal">
  <!-- <button mat-button class="{{policyButtonClass}}" class="buttonStyle" (click)="showPolicy()">{{'Bottom.policy' | translate }}</button> -->
  <!-- <button mat-button class="{{clientButtonClass}}" class="buttonStyle" (click)="showClientFaq()">{{'Bottom.forClients' | translate }}</button> -->
  <!-- <button mat-button class="{{partnerButtonClass}}" class="buttonStyle" (click)="showPartnerFaq()">{{'Bottom.forPartners' | translate }}</button> -->
  <!-- <button mat-button class="{{businessButtonClass}}" class="buttonStyle" (click)="showInformationForBusinessPartners()">{{'Bottom.nobiForBusiness' | translate }}</button> -->
  <!-- <button mat-button class="{{romanticButtonClass}}" class="buttonStyle" (click)="showRomanticAdventures()">{{'Bottom.romanticTrip' | translate }}</button> -->
  <!-- <hr> -->
  <!-- <div *ngIf="isPolicy" class="text_padding">
      <h1>Политика конфиденциальности</h1>
      <p>Если вы продолжаете пользоваться нашим сайтом, вы соглашаетесь с нижеследующими правилами. Мы обеспечиваем соответствие международному регламенту по защите данных и требования GDPR (Генеральный регламент по защите персональных данных).</p>
      <p>Данные правила использования объясняют каким образом мы собираем и используем ваши персональные данные. Если у вас остались вопросы или же вам нужны пояснения, в отношении использования ваших персональных данных, свяжитесь с нами посредством контактной формы.</p>
      <h3>Файлы куки</h3>
      <p>Файлы куки хранятся в вашем браузере с целью персонализации вашего опыта использования сайта. Принимая политику куки, вы соглашаетесь с использованием файлов куки. Мы используем куки для повышения качества опыта нахождения на нашем сайте, а также для получения отчетов об ошибках, возникающих при использовании нашего сайта.</p>
      <h3>Персональные данные</h3>
      <p>Персональная информация, которую мы собираем в ходе вашего посещения сайта может включать имя email адрес, номер телефона и IP адрес. Для пользования услугами нашего сайта, может понадобиться прохождение процедуры регистрации или предоставление персональной информации.  Мы собираем эту информацию лишь тогда, когда вы совершаете покупку или регистрацию на сайте и эта информация требуется нам для того, чтобы определить и зарегистрировать вас в качестве пользователя, подтвердить покупку и связаться с вами по поводу наших  услуг и товаров, или для того, чтобы ответить на ваши запросы. Номер вашего телефона может быть использован для связи с вами в целях подтверждения покупки в том случае, если у вас нет возможности подтвердить ее через электронный адрес. Мы не передаем эту информацию третьим лицам, и Вы можете быть уверенным в том, что эта информация надежна сохранена.</p>
      <h3>Ваше местоположение</h3>
      <p>При использовании нашего сайта, мы можем использовать ваш IP адрес для определения вашего местоположения. Информация о вашем местоположении поможет нам персонализировать ваш опыт нахождения на сайте и использования некоторых модулей сайта, например, поиск объявлений на карте. Ваше местоположение поможет нам показывать релевантные объявления вблизи вашего местонахождения. Мы также используем ваше местоположение для показа таргетированной рекламы от Google и баннеров.</p>
      <h3>Информация о вашей кредитной карте</h3>
      <p>При совершении покупки на нашем сайте, вы вводите данные своей кредитной карты или адрес платежной системы для завершения процесса покупки. Учитывая тот факт, что весь платежный процесс обрабатывается платежными системами, мы не храним данные вашей кредитной карты и не имеем доступ к платежной информации ни на одном из этапов процесса покупки.</p>
      <h3>Системные уведомления</h3>
      <p>Используя наш сайт, вы даете свое согласие на получение системных уведомлений на ваш электронный адрес. Уведомления могут содержать информацию о добавленном объявлении, предупреждение о том, что период действия объявления истекает и оно будет удалено с сайта, если вы не обновите его, а также другие виды уведомлений, относящиеся к работе сайта. Системные уведомления служат в качестве напоминаний или полезных советов, повышающих ваш опыт нахождения на сайте. Вы не можете отписаться от системных уведомлений и единственный способ прекратить их получать – это удалить вашу учетную запись на нашем сайте</p>
    </div> -->


  <!-- <h1 *ngIf="isClient || isPartner">{{"FrequentlyAskedQuestions.faq" | translate}}</h1> -->
  <div *ngIf="isClient">
    <main>
      <!-- <details>
        <summary>Изменить бронирование</summary>
        <div class="faq__content">
          <p>Если вам нужно изменить даты, категорию номера или количество гостей в бронировании, пожалуйста, заполните форму обратной связи — мы всё проверим и свяжемся с вами. </p>
        </div>
      </details>

        <details>
        <summary>Отменить бронирование</summary>
        <div class="faq__content">
          <p>Если вам нужно отменить бронирование, это можно сделать в «Личном кабинете» на сайте . В случае ошибки при отмене, пожалуйста, заполните форму обратной связи — мы вернёмся с ответом сразу, как только уточним информацию.</p>
        </div>
      </details>

      <details>
        <summary>Подтвердить бронирование</summary>
        <div class="faq__content">
          <p>Если вы уже оставили заявку по бронированию, но пока не получили ответ, не переживайте: ваш запрос в работе, и мы постараемся решить его в максимально короткие сроки. К сожалению, из-за большого количества обращений время обработки запросов увеличилось. Как только бронирование будет подтверждено, мы свяжемся с вами по электронной почте. Если вы не получили подтверждение бронирования на электронную почту, первым делом убедитесь, что проверяете именну ту почту, которая указана в «Личном кабинете» на Ostrovok.ru, затем просмотрите папку «Спам». Если письма всё-таки нигде нет, не переживайте и просто напишите на nobitour@gmail.com — мы отправим вам подтверждение вручную.</p>
        </div>
      </details> -->

      <div class="container">
        

        <label class="accordion accordion--1" for="open-2">
          <input class="accordion__open" id="open-2" type="radio" name="acoordion-2">
          <input class="accordion__close" id="close-2" type="radio" name="acoordion-2">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">02</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q2" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <!-- <ul> -->
                <!-- <li>Subscribe</li>
                      <li>Like</li>
                      <li>Comment</li> -->
                <p>{{"FrequentlyAskedQuestions.a2" | translate}}</p>
                <!-- </ul> -->
              </dd>
            </dl>
          </div>
          <label for="close-2" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-3">
          <input class="accordion__open" id="open-3" type="radio" name="acoordion-3">
          <input class="accordion__close" id="close-3" type="radio" name="acoordion-3">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">03</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q3" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <!-- <ul> -->
                <!-- <li>Subscribe</li>
                    <li>Like</li>
                    <li>Comment</li> -->
                <p>{{"FrequentlyAskedQuestions.a3" | translate}}</p>
                <!-- </ul> -->
              </dd>
            </dl>
          </div>
          <label for="close-3" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        
        

        <label class="accordion accordion--1" for="open-5">
          <input class="accordion__open" id="open-5" type="radio" name="acoordion-5">
          <input class="accordion__close" id="close-5" type="radio" name="acoordion-5">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">05</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q5" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a5" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-5" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-6">
          <input class="accordion__open" id="open-6" type="radio" name="acoordion-6">
          <input class="accordion__close" id="close-6" type="radio" name="acoordion-6">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">06</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q6" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <!-- <ul> -->
                <!-- <li>Subscribe</li>
              <li>Like</li>
              <li>Comment</li> -->
                <p>{{"FrequentlyAskedQuestions.a6" | translate}}</p>
                <p>{{"FrequentlyAskedQuestions.a6.2" | translate}}</p>
                <!-- </ul> -->
              </dd>
            </dl>
          </div>
          <label for="close-6" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-7">
          <input class="accordion__open" id="open-7" type="radio" name="acoordion-7">
          <input class="accordion__close" id="close-7" type="radio" name="acoordion-7">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">07</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q7" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a7" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-7" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-8">
          <input class="accordion__open" id="open-8" type="radio" name="acoordion-8">
          <input class="accordion__close" id="close-8" type="radio" name="acoordion-8">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">08</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q8" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a8" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-8" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        

        <label class="accordion accordion--1" for="open-11">
          <input class="accordion__open" id="open-11" type="radio" name="acoordion-11">
          <input class="accordion__close" id="close-11" type="radio" name="acoordion-11">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">11</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q11" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a11" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-11" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-12">
          <input class="accordion__open" id="open-12" type="radio" name="acoordion-12">
          <input class="accordion__close" id="close-12" type="radio" name="acoordion-12">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">12</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q12" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a12" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-12" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-13">
          <input class="accordion__open" id="open-13" type="radio" name="acoordion-13">
          <input class="accordion__close" id="close-13" type="radio" name="acoordion-13">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">13</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q13" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a13" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-13" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-14">
          <input class="accordion__open" id="open-14" type="radio" name="acoordion-14">
          <input class="accordion__close" id="close-14" type="radio" name="acoordion-14">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">14</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q14" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a14" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-14" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-15">
          <input class="accordion__open" id="open-15" type="radio" name="acoordion-15">
          <input class="accordion__close" id="close-15" type="radio" name="acoordion-15">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">15</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q15" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a15" | translate}}</p>
                <p>{{"FrequentlyAskedQuestions.a15.2" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-15" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-16">
          <input class="accordion__open" id="open-16" type="radio" name="acoordion-16">
          <input class="accordion__close" id="close-16" type="radio" name="acoordion-16">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">16</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q16" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a16" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-16" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-17">
          <input class="accordion__open" id="open-17" type="radio" name="acoordion-17">
          <input class="accordion__close" id="close-17" type="radio" name="acoordion-17">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">17</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q17" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a17" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-17" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-18">
          <input class="accordion__open" id="open-18" type="radio" name="acoordion-18">
          <input class="accordion__close" id="close-18" type="radio" name="acoordion-18">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">18</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q18" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a18" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-18" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-19">
          <input class="accordion__open" id="open-19" type="radio" name="acoordion-19">
          <input class="accordion__close" id="close-19" type="radio" name="acoordion-19">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">19</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q19" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a19" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-19" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-20">
          <input class="accordion__open" id="open-20" type="radio" name="acoordion-20">
          <input class="accordion__close" id="close-20" type="radio" name="acoordion-20">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">20</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q20" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a20" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-20" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>

        <label class="accordion accordion--1" for="open-21">
          <input class="accordion__open" id="open-21" type="radio" name="acoordion-21">
          <input class="accordion__close" id="close-21" type="radio" name="acoordion-21">
          <div class="accordion__tab">Question</div>
          <div class="accordion__wrapper">
            <dl class="accordion__box">
              <dt class="accordion__patition">
                <!-- <span class="accordion__number">21</span> -->
                <span class="accordion__title">{{"FrequentlyAskedQuestions.q21" | translate}}</span>
              </dt>
              <dd class="accordion__text">
                <p>{{"FrequentlyAskedQuestions.a21" | translate}}</p>
              </dd>
            </dl>
          </div>
          <label for="close-21" class="accordion__button">
            <span class="accordion__buttonText">CLOSE</span>
          </label>
        </label>
</div> 

        <!-- <details>
          <summary>{{"FrequentlyAskedQuestions.q2" | translate}}</summary>
          <div class="faq__content">
            <p>{{"FrequentlyAskedQuestions.a2" | translate}}</p>
          </div>
        </details>
    </div> -->


        <!-- <details >
        <summary>{{"FrequentlyAskedQuestions.q1" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a1p1" | translate}} <a href="https://nobiarts.com" target="_blank" style="text-decoration: underline;">Nobiarts.com </a> {{"FrequentlyAskedQuestions.a1p2" | translate}}</p>
            <li>{{"FrequentlyAskedQuestions.a1li1" | translate}}</li>

            <li>{{"FrequentlyAskedQuestions.a1li2" | translate}}</li>

            <li>{{"FrequentlyAskedQuestions.a1li3" | translate}}</li>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q2" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a2" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q3" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a3" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q4" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a4" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q5" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a5" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q6" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a6" | translate}}</p>
          <p>{{"FrequentlyAskedQuestions.a6.2" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q7" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a7" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q8" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a8" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q9" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a9" | translate}}</p>
          <p>{{"FrequentlyAskedQuestions.a9.2" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q10" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a10" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q11" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a11" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q12" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a12" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q13" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a13" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q14" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a14" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q15" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a15" | translate}}</p>
          <p>{{"FrequentlyAskedQuestions.a15.2" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q16" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a16" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q17" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a17" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q18" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a18" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q19" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a19" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q20" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a20" | translate}}</p>
        </div>
      </details> -->

        <!-- <details>
        <summary>{{"FrequentlyAskedQuestions.q21" | translate}}</summary>
        <div class="faq__content">
          <p>{{"FrequentlyAskedQuestions.a21" | translate}}</p>
        </div>
      </details>  -->



    </main>
  </div>

</div>

<app-bottom *ngIf="!showLoader" style="position: relative;"></app-bottom>